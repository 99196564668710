/** @jsx jsx */
import { Styled, jsx, Flex } from "theme-ui"
import  { Link, graphql } from 'gatsby'
import Layout from "../components/layout"

import { Container } from '../components/item'
import SEO from "../components/seo"

import styled from "@emotion/styled"

export const query = graphql`
  query {
    welding: file(relativePath: { eq: "welding-blanket-inuse2.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    interior: file(relativePath: { eq: "interior-shot.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    slide1: file(relativePath: { eq: "fire-blanket-1.png"}) {
      childImageSharp {
        fluid(maxWidth: 410) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const StyledBack = styled(Link)`
  color: white;
  text-decoration: none;

  // position: relative;

  span:first-child {
    transition: all 0.1s linear;
    display: inline-block;
  }

  span:last-child {
    border-bottom: 1px dotted rgba(255,255,255,0.3);
  }

  &:hover {
    text-decoration: none;
    span:first-child {
      // position: absolute;
      // left: -2rem;
      transform: translateX(-0.2rem)
    }
  }

`

const IndexPage = ({data}) => (

  <Layout full mainStyles={{background: '#000'}}>
    <SEO title="Home" />

    <Flex as="section" bg={'black'} color={'white'}>
      <Container>
        <Flex py={7} sx={{
          color: 'white',
          bg: 'black',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          paddingTop: '150px'
        }}>
          <Styled.h3 sx={{ variant: 'text.caps'}}>Whoops! The page you were looking for seems to be missing.</Styled.h3>
          <Styled.p sx={{
            maxWidth: 820,
            variant: 'text.content'
          }}>We've logged this event, and will work on making sure you don't see this again.</Styled.p>
          <StyledBack to="/"><span>⭠</span> <span>Go Home</span></StyledBack>
        </Flex>
      </Container>
    </Flex>
  
  </Layout>
)

export default IndexPage
